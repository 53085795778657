.task {
	display: flex;
	align-items: flex-start;
	border-bottom: 0px solid rgb(112, 112, 112);
	padding-bottom: 1px;
	padding-bottom: 1px;
}
.task input {
	width: 100%;
	text-transform: uppercase;
	background-color: transparent;
	border: 0;
	font-weight: 400;
}
.task input:focus {
	outline: none;
}
.task__header {
	border-radius: 5px;
}
.task__body {
	flex: 1;
	padding: 5px;
}
.task__title {
	color: blue;
	font-weight: 800;
	padding-top: 5px;
}
.task__title--completed {
	color: green;
	text-decoration: line-through;
}
.task__title--notcompleted {
	color: red;
}

.task__footer {
	color: grey;
	border-top: 1px solid rgb(112, 112, 112);
	padding-top: 15px;
	padding-bottom: 0px;
	display: flex;
	font-size: small;
	justify-content: space-between;
	margin-top: 20px;
	cursor: pointer;
}
