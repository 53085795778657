body {
	color: Black;
	background: #e6ecf0;
}

.app {
	height: 100vh;
	max-width: 1300px;
	margin-left: auto;
	margin-right: auto;
	padding: 10px;
}
.footer {
	position: relative;
	bottom: 0;
	width: 100%;
	height: 2.5rem;
	color: grey;
	font-size: small;
	padding-top: 20px;
	justify-content: space-between;
}
