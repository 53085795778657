.task {
	flex: 0.4;
	border-right: 0px solid black;
	border-left: 0px solid black;
	min-width: fit-content;
	overflow-y: scroll;
	/* Hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none; /*IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.task__header {
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 100;
	border: 1px solid rgb(51, 51, 51);
	padding: 15px 20px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.task::-webkit-scrollbar {
	display: none;
}

.task__header > h2 {
	font-size: 20px;
	font-weight: 800;
}
